










































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";

import Site from "@/models/Site";
import SitesServices from "@/services/SitesServices";
import TypeTravauxService from "@/services/TypeTravauxService";
import table from "@/utils/table";
import { OperationType } from "@/utils/Enums";

import { Guid } from "guid-typescript";
import OperationService from "@/services/OperationService";
const OPERATION_SITE_OPERATION_TYPE_ID: string = "operationFamilyId";
const OPERATION_SITE_OPERATION_TYPE_NAME: string = "operationFamilyName";
const OPERATION_SITE_OPERATION_TYPE_PATH: string = "operationFamilyPath";

@Component({
  components: {
    Modal: () => import("@/components/Shared/Modal.vue"),
  },
})
export default class OperationTypeTableModal extends Vue {
  /**
   * Defines if the modal is opened or not
   */
  @Prop({ default: false }) public show!: boolean;
  /**
   * Property set when the modal is opened
   */
  @Prop({ default: undefined }) public props!: any;

  @Prop({ default: false }) private firstLevelDisabled!: boolean;
  private loaded: boolean = false;
  private lvl1: any = null;
  private lvl2: any = null;
  private lvl3: any = null;
  private path: string = "";
  private get mainOption(): string {
    const operationType = this.props.rowData.operationType;
    let mainOption = this.$t("bopModelSetup.OperationDiverse").toString();
    if (operationType == OperationType.OperationPrincipal) {
      mainOption = this.$t("bopModelSetup.OperationPrincipale").toString();
    }
    return mainOption;
  }
  private get options() {
    return this.props.rowField.options;
  }
  private get isDuplicationField() : boolean{
    return this.props?.rowField.isDuplicationField;
  }
  /**
   * Methods
   */
  public validate() {
    let selectedValue:any = null;
    this.path = "";
    if (this.lvl1 !== null) {
      selectedValue = this.lvl1;
      this.path += `${selectedValue.id}`;

      if (this.lvl2 !== null) {
        selectedValue = this.lvl2;
        this.path += `.${selectedValue.id}`;

        if (this.lvl3 !== null) {
          selectedValue = this.lvl3;
          this.path += `.${selectedValue.id}`;
        }
      }
    }

    this.props.rowData[OPERATION_SITE_OPERATION_TYPE_ID] = selectedValue?.id;
    this.props.rowData[OPERATION_SITE_OPERATION_TYPE_NAME] = selectedValue?.name;
    this.props.rowData[OPERATION_SITE_OPERATION_TYPE_PATH] = this.path;
    this.$store.commit("changeHasBeenModified", true);
    if(this.isDuplicationField) {
    this.$emit("validate-duplicate", this.props.rowData);
    }else{
    this.$emit("validate", this.props.rowData);
    }
  }

  /**
   * Hooks
   */
  public async mounted() {}
  /***
   * watchers
   */
  @Watch("show")
  async onShowChange(value: boolean, oldValue: boolean) {
    if (value == false) {
      this.lvl1 = null;
      this.lvl2 = null;
      this.lvl3 = null;
    } else {

      const path = this.props.rowData[OPERATION_SITE_OPERATION_TYPE_PATH] ?? "";
      const elements = path.split(".").map((el:string)=> el.toLowerCase());

      this.lvl1 = (elements[0] === undefined || !elements[0].length? null : this.options[elements[0]]);
      this.lvl2 =
        elements[1] === undefined|| !elements[1].length ? null : this.lvl1.children[elements[1]];
      this.lvl3 =
        elements[2] === undefined || !elements[2].length? null : this.lvl2.children[elements[2]];
    }
    this.loaded = true;
    this.$forceUpdate();
  }
}
